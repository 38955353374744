<template>

  <ul id="slide-out" class="sidenav">
    <div class="card blue-grey">
        <div class="card-content white-text">
            <span class="card-title">William Forrest</span>
            <hr>
            <p>Software Developer</p>
            <br>
            <p>contact@williamforrest.me</p>
        </div>
    </div>

    <li><a class="waves-effect sidenav-close" href="#About"><i class="material-icons">account_box</i>About</a></li>
    <li>
        <div class="divider"></div>
    </li>
    <li><a class="waves-effect sidenav-close" href="#Skills"><i class="material-icons">assignment_turned_in</i>Skills</a></li>
    <li>
        <div class="divider"></div>
    </li>
    <li><a class="waves-effect sidenav-close" href="#Experience"><i class="material-icons">business</i>Work history</a></li>
    <li>
        <div class="divider"></div>
    </li>
    <li><a class="waves-effect sidenav-close" href="#Contact"><i class="material-icons">email</i>Contact</a></li>
    <li>
        <div class="divider"></div>
    </li>
  </ul>
<a href="#" data-target="slide-out" class="sidenav-trigger btn-large cyan pulse left fixed"><i class="material-icons medium">menu</i></a>

<body>



    <div class="container" id="app">
        <div class="row col s12">
            <PersonalAbout class="row" id="About"></PersonalAbout>
            <PersonalSkills class="row" id="Skills" v-bind:skills="skills"></PersonalSkills>
            <PersonalExperience class="row" id="Experience" v-bind:experience="experience"></PersonalExperience>
            <PersonalContact class="row" id="Contact"></PersonalContact>

        </div>
        <div class="col s12">
            <h6 class="row center-align">Having trouble viewing this page?<br>This website may not render properly on
                older distributions of
                Internet Explorer.</h6>
        </div>

    </div>
</body>
</template>

<script>
import PersonalAbout from './components/About.vue'
import PersonalContact from './components/Contact.vue'
import PersonalExperience from './components/Experience.vue'
import PersonalSkills from './components/PersonalSkills.vue'
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css'

export default {
  name: 'App',
  components: {
    PersonalAbout,
    PersonalContact,
    PersonalExperience,
    PersonalSkills
  },
  mounted () {
    M.AutoInit()
  },
  data() {
    return {
    skills: [
      {
        name: "Programming languages",
        items: [
          "Java",
          "Python",
          "C",
          "C++",
          "C#",
          "Javascript",
          "Typescript",
          "Visual Basic / VBA"
        ]
      },
      {
        name: "Databases",
        items: ["Mongo", "MySQL", "Oracle", "Postgres", "TSQL"]
      },
      {
        name: "Javascript frameworks",
        items: ["AngularJS", "Angular", "Vue.js"]
      },
      {
        name: "Testing frameworks",
        items: ["JUnit", "Mockito", "Jasmine", "Selenium"]
      },
      {
        name: "Other coding frameworks",
        items: ["QT", ".Net", "Spring", "SpringBoot", "Vertx"]
      },
      {
        name: "Familiar operating systems",
        items: ["Windows", "Mac", "Linux"]
      }
    ],
    experience: [{
      name: "Education",
      items: [{
        date: "Sep. 2014 - Aug. 2016",
        location: "Carleton University, Ottawa ON",
        info: ["Obtained a BSc of Computer Science Major, with Mathematics Minor"]
      }]
    },
    {
      name: "Work history",
      items: [{
        date: "Aug. 2018 - Current",
        location: "Mindbridge Ai",
        title: "Software Developer",
        info: ["Full-Stack, AngularJS, Angular, Typescript, Mongo, Postgres, Java EE with SpringBoot.",
          "Designed database models, indexes, and appropriate endpoint API for features",
          "Implemented financial reports reports using Apache POI, MongoDB (NoSQL), Postgres, and Typescript",
          "Traced and debugged production issues using ElasticSearch and Kibana",
          "Created graphical visualizations for user data using D3 and Highcharts",
          "Utilized Karma, Jasmine, and JUnit for application testing"]
      }, {
        date: "Jan. 2017 - Jul. 2018",
        location: "Tata Consultancy Services",
        title: "Software Developer",
        info: ["Upgraded existing legacy Websphere REST Java EE projects to Kubernetes Docker containers for ease",
          "Utilized Jenkins, Bamboo and Docker to allow continuous building and deployment",
          "Created reports utilizing Jasper to show client financial transactions as a part of client infrastructure upgrade",
          "Used Selenium framework to automate our test process for AngularJS web applications with Python"]
      }, {
        date: "May. 2016 - Sep. 2016",
        location: "Defense Research And Development Canada",
        title: "Social Statistician",
        info: ["Quantified, Analyzed and provided statistical support on project metadata.",
          "Created and developed macros for excel, using TSQL backend."]
      }, {
        date: "Mar. 2016 - Apr. 2016",
        location: "Defense Research And Development Canada",
        title: "Social Statistician",
        info: ["Quantified, Analyzed and provided statistical support on project metadata.",
          "Created and developed macros for excel, using Sharepoint backend."]
      }, {
        date: "May 2015 - Aug. 2015",
        location: "Canada Revenue Agency",
        title: "Individual Services and Benefits Control Clerk",
        info: ["Controlled and Managed Documentation Workflow and Inventory"]
      }]
    }
    ]
  }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
