<script setup>
import { ref, computed, defineProps } from 'vue'

const props = defineProps({ skills: { required: true, default : () => []}})
// eslint-disable-next-line no-unused-vars
const filter = ref("")
// eslint-disable-next-line no-unused-vars
const filteredSkills = computed(() => {

      if (!props.skills) {
          return [];
      }
      let skillCopy = JSON.parse(JSON.stringify(props.skills));
      if (filter.value && filter.value !== "") {
        for (let i = 0; i < skillCopy.length; i++) {
          for (let j = skillCopy[i].items.length - 1; j >= 0; j--) {
            if (
              skillCopy[i].items[j]
                .toLowerCase()
                .indexOf(filter.value.trim().toLowerCase()) === -1
            ) {
              skillCopy[i].items.splice(j, 1);
            }
          }
        }
      }
      return skillCopy;
  })
</script>
<template>
<div class="card blue-grey darken-4">
  <div class="row valign-wrapper">
    <div class="col">
      <h2 class="white-text card-content  left-align">Software Skills</h2>
    </div>
    <div class="col s2 offset-s5">
      <input type="text" class="card-content white-text  left-align" v-model="filter" placeholder="Search skills" />
    </div>
  </div>

  <div class="row" v-for="area in filteredSkills" :key="area.name">
    <div class="card-content white-text" v-if="area.items.length > 0">
      <span class="card-title">{{area.name}}</span>
      <div>
        <transition-group name="fade">
          <span class="col s3" v-for="elem in area.items" :key="elem">

            <div class="card blue-grey darken-1">
              <div class="card-content white-text">
                <span class="card-title">{{elem}}</span>
              </div>
            </div>

          </span>
        </transition-group>

      </div>
    </div>
  </div>
</div>
</template>