<template>
    <div class="card grey darken-3">
      <div>
        <div> <h2 class="white-text card-content left-align">Contact</h2></div>
      </div>
      <section>
        <div class="card card-content grey darken-1 white-text">
          <p>Please contact me at: <a href="mailto:contact@williamforrest.me">contact@williamforrest.me</a></p>
        </div>
        </section>
    </div>
</template>
<script>
export default {
  name: 'PersonalContact',
  props: {
  }
}
</script>