<template>
    <div class="card teal darken-4">
        <h1 class="white-text card-content center-align">William Forrest</h1>
        <div class="section">
        <div class="card teal darken-3">
        <div class="white-text card-content">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;Welcome to my website. I have been using computers from a very young age, having had to learn DOS to stop pestering my parents to play the games from the floppy drive.</p>
        <br>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;Many years later, I was writing my first program; It was a simple calculator in Visual Basic 6. At the time it was novel enough to not use my pocket calculator, but the idea grew into a fascination of the many possibilites of things that can be created. Following this idea, I originally entered school as chemical engineering undergraduate, and then switched to computer science later on to return to programming. Very few engineering disciplines have the ability to see the results as quickly as software could, and I find it gives a sense of satisfaction completing a project.</p>
        <br>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;My hobbies include martial arts, board games and reading. I hold a black belt in karate, which I earned after 10 years of practising. I completed my degree in 2016, focusing on AI, security theory, and computer vision. </p>
        <br>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;Currently I am employed at Mindbridge Analytics, helping to create the next generation of auditing software!</p>
        </div>
        </div>
        </div>
        </div>
</template>
<script>
export default {
  name: 'PersonalAbout',
  props: {
  }
}
</script>