<template>
<div class="card cyan darken-4">
  <div class="row">
    <div>
      <h2 class="white-text card-content left-align">Experience</h2>
    </div>
  </div>
  <div class="row" v-for="area in experience" :key="area.name">
    <div class="card-content white-text" v-if="area.items.length > 0">
      <span class="card-title">{{area.name}}</span>
      <div class="card-content">
        <div v-for="elem in area.items" :key="elem.title">
          <div class="col s12 card card-content cyan darken-3">
            <span class="col s3">
              <div>{{elem.date}}</div>
            </span>
            <span class="col s9">
              <div class="card-title left-align">{{elem.location}} {{elem.title ? " - " : ""}} {{elem.title}}</div>
              <br />
              <li class="col s11 offset-s1" v-for="infoline in elem.info" :key="infoline">
                {{infoline}}
              </li>

            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'PersonalExperience',
  props: ["experience"]
}
</script>